import useIsInstalledMobile from "@/hooks/useIsInstalledMobile";
import { LinearProgress } from "@mui/material";
import { NextSeo } from "next-seo";
import Router from "next/router";
import { useState } from "react";
import styled, { useTheme } from "styled-components";

const Container = styled.div<{
  $full: boolean;
}>`
  min-height: ${({ $full, theme }) =>
    $full ? "100vh" : `calc(100vh - ${theme.headerHeight})`};
  background-color: ${({ theme }) => theme.colors.themed.major};
  margin: 0;
  padding: 0;
`;

const Page = styled.main<{
  $mobile: boolean;
  $hideX: boolean;
}>`
  display: flex;
  flex-direction: column;
  ${({ $hideX }) => $hideX && "overflow-x: hidden;"}
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.themed.major};
  font-family: ${({ theme }) => theme.typography.fontFamily};
  ${({ $mobile }) => $mobile && "padding-top: 50px;"}
`;

interface LayoutProps {
  /**
   * The content of the page
   */
  children: any;
  /**
   * The title of the page (a suffix will be added)
   */
  title: string;
  /**
   * Whether the page is restricted to authenticated users only
   */
  restricted?: boolean;
  /**
   * A url to a background image, if any. It will cover the entire page. Should be an absolute url starting with `/assets/...`
   */
  background?: string;
  /**
   * The description of the page
   */
  description?: string;
  /**
   * Whether to show a loading bar at the top of the page. This will trigger an indeterminate `LinearProgress`
   */
  loading?: boolean;
  /**
   * If provided, this will replace the default title suffix and title in open graph tags. If omitted, the `title` will be used.
   */
  ogTitle?: string;
  /**
   * Whether to show the overflow-x of the page. If using position: sticky within the page, enable this.
   */
  showOverflowX?: boolean;
  /**
   * Whether to fill the entire height
   */
  fullVh?: boolean;
}

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - ${({ theme }) => theme.headerHeight});
  background-size: cover;
  background-position: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    background: none !important;
  }
`;

/**
 * The layout of the page. This is the wrapper for all pages.
 */
export default function Layout({
  children,
  title,
  background,
  description,
  loading,
  ogTitle,
  showOverflowX = false,
  fullVh = false,
}: LayoutProps) {
  const isMobilePWA = useIsInstalledMobile();
  const theme = useTheme();
  const [pageLoading, setPageLoading] = useState<boolean | null>(false);
  let bg = "";
  if (background) {
    bg = `url(${background})`;
  }
  Router.events.on("routeChangeStart", () => {
    setPageLoading(false);
    // TODO: this causes a state update after unmount (only works with page transitions)
    // setTimeout(() => {
    //   setPageLoading((prev) => {
    //     if (prev === false) {
    //       return true;
    //     }
    //     return null;
    //   });
    // }, 300);
  });
  Router.events.on("routeChangeComplete", () => {
    setPageLoading(null);
  });
  Router.events.on("routeChangeError", () => {
    setPageLoading(null);
  });

  return (
    <>
      <NextSeo
        title={title === "Home" ? "2AM: Learning Tools and Flashcards" : title}
        titleTemplate={title === "Home" ? "%s" : undefined}
        description={description}
        themeColor={theme.colors.themed.major || "#f5f5f5"}
        openGraph={
          ogTitle
            ? {
                title: ogTitle,
              }
            : undefined
        }
      />
      {(loading || pageLoading) && (
        <LinearProgress
          color="secondary"
          sx={{
            width: "100vw",
            position: "absolute",
            top: "0px",
            zIndex: 9999,
          }}
        />
      )}
      <Page $mobile={isMobilePWA} $hideX={!showOverflowX}>
        <Container $full={fullVh}>
          <ImageWrapper
            style={{
              backgroundImage: bg || "none",
            }}
          >
            {children}
          </ImageWrapper>
        </Container>
      </Page>
    </>
  );
}
