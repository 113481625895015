import CodePreviewImage from "@/assets/codePreview.png";
import CalendarEmoji from "@/assets/emojis/calendar.png";
import ClockEmoji from "@/assets/emojis/clock.png";
import HeartEmoji from "@/assets/emojis/heart.png";
import LightEmoji from "@/assets/emojis/light.png";
import LockedEmoji from "@/assets/emojis/locked.png";
import MoneyEmoji from "@/assets/emojis/money.png";
import PartyEmoji from "@/assets/emojis/party.png";
import PhoneEmoji from "@/assets/emojis/phone.png";
import RocketEmoji from "@/assets/emojis/rocket.png";
import ScoreEmoji from "@/assets/emojis/score.png";
import FrontPageImage from "@/assets/frontPageImage.png";
import QuestionPreviewLight from "@/assets/questionPreview.svg";
import QuestionPreviewCorrectLight from "@/assets/questionPreviewCorrect.svg";
import QuestionPreviewCorrectDark from "@/assets/questionPreviewCorrectDark.svg";
import QuestionPreviewDark from "@/assets/questionPreviewDark.svg";
import QuestionPreviewIncorrectLight from "@/assets/questionPreviewIncorrect.svg";
import QuestionPreviewIncorrectDark from "@/assets/questionPreviewIncorrectDark.svg";
import UnderlineEmphasis from "@/atoms/underline-emphasis-wrapper";
import Layout from "@/species/page";
import { Button, Tooltip, Typography } from "@mui/material";
import type { NextPage } from "next";
import Image from "next/image";
import { useRouter } from "next/router";
import { useRef } from "react";
import { MdInfoOutline } from "react-icons/md";
import styled, { useTheme } from "styled-components";
import { useIntersectionObserver } from "usehooks-ts";

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
`;

const IntroArea = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;
  padding-top: 1rem;
  .image-wrapper {
    width: clamp(300px, 60%, 900px);
    aspect-ratio: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &::before {
      content: "";
      display: block;
      background-color: ${({ theme }) => theme.colors.brand.color5};
      position: absolute;
      width: 80%;
      aspect-ratio: 1;
      z-index: 1;
      border-radius: 50%;
      opacity: 0.2;
    }
  }
  display: flex;
  flex-direction: row;
  .info-items-wrapper {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    padding-right: 2rem;
    gap: 2rem;
    .info-item {
      width: min(100%, 400px);
      padding: 1rem;
      border-radius: ${({ theme }) => theme.borderRadius.large};
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .main-text {
        font-size: 2rem;
        font-weight: 700;
        display: block;
      }
      &:nth-child(1) {
        background-color: rgba(
          ${({ theme }) => theme.colors.brand.color1RGB},
          0.2
        );
      }
      &:nth-child(2) {
        background-color: rgba(
          ${({ theme }) => theme.colors.brand.color2RGB},
          0.2
        );
      }
      &:nth-child(3) {
        background-color: rgba(
          ${({ theme }) => theme.colors.brand.color3RGB},
          0.2
        );
      }
      &:nth-child(4) {
        background-color: rgba(
          ${({ theme }) => theme.colors.brand.color4RGB},
          0.2
        );
      }
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding-top: 1.2rem;
    flex-direction: column;
    align-items: center;
    .info-items-wrapper {
      width: 100%;
      padding: 1rem;
      gap: 1rem;
      .info-item {
        width: 100%;
      }
    }
  }
`;

const ProductImagesSection = styled.div`
  .section1 {
    display: grid;
    grid-template-columns: 4fr 6fr;
    justify-items: center;
    padding: 2rem;
    min-height: 100vh;

    /* place-items: center; */
    .images {
      grid-column: 2;
      width: clamp(300px, 80%, 1000px);
      aspect-ratio: 1.564;
      position: sticky;
      top: calc(3rem + ${({ theme }) => theme.headerHeight});
      /* transform: translateY(-50%); */
      /* padding-top: 50%; */

      .second-image,
      .third-image,
      .first-image {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        &.visible {
          opacity: 1;
        }
      }
    }
    .text {
      grid-column: 1;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding-left: 2rem;
      .title {
        font-weight: 500;
      }
      .info-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 1rem;
        margin-top: 1rem;
      }
    }
  }
  .section2 {
    background-color: ${({ theme }) => theme.colors.peripheral.majorVariant};
  }
  .image {
    border-radius: ${({ theme }) => theme.borderRadius.large};
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    .images {
      display: none;
    }
    .section1 {
      grid-template-columns: 1fr;
      .text {
        padding-right: 2rem;
        h5 {
          margin-top: 3rem !important;
        }
        .imageRef {
          display: none;
        }
      }
    }
  }
`;

const AboutSection = styled.div`
  background-color: black;
  color: ${({ theme }) => theme.colors.themed.alwaysLight};
  display: grid;
  grid-template-columns: 6fr 4fr;
  justify-items: center;
  padding: 2rem;
  gap: 2rem;
  .image {
    grid-column: 1;
    width: clamp(300px, 80%, 1000px);
    .inner-image {
      width: 100%;
      height: auto;
    }
  }
  .text {
    grid-column: 2;
    padding-right: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    grid-template-columns: 1fr;
    .image,
    .text {
      grid-column: 1;
    }
    .text {
      text-align: center;
      align-items: center;
      padding-right: 0;
    }
  }
`;

const Home: NextPage = () => {
  const router = useRouter();
  const theme = useTheme();
  const image2Ref = useRef<any | null>(null);
  const image2Entry = useIntersectionObserver(image2Ref, {});
  const image3Ref = useRef<any | null>(null);
  const image3Entry = useIntersectionObserver(image3Ref, {});
  const imageVisible = image3Entry?.isIntersecting
    ? 3
    : image2Entry?.isIntersecting
    ? 2
    : 1;
  return (
    <Layout
      title="Home"
      ogTitle="Superior studying for superior students"
      showOverflowX
    >
      <Hero>
        <Typography
          variant="h1"
          className="hero-title"
          sx={{
            fontSize: "clamp(2rem, 5vw, 3rem)",
            fontWeight: 700,
            textAlign: "center",
            padding: "0 1rem",
          }}
        >
          Superior studying for superior students
        </Typography>
        <Typography
          variant="h2"
          className="hero-subtitle"
          sx={{
            fontSize: "clamp(1.2rem, 3vw, 1.5rem)",
            fontWeight: 500,
            textAlign: "center",
            padding: "0 1rem",
            marginTop: "1rem",
          }}
        >
          Ace your next test with proven study methods, all for free.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            fontSize: "1.2rem",
            marginTop: "2rem",
            borderRadius: "1rem",
            boxShadow: "0px 2px 10px 2px rgba(0,0,0,0.2)",
          }}
          onClick={() => {
            router.push("/import");
          }}
        >
          Import a set from Quizlet or Knowt
        </Button>
      </Hero>
      <IntroArea>
        <div className="image-wrapper">
          <Image
            src={FrontPageImage}
            alt="Computer displaying 2AM study set"
            priority
            placeholder="blur"
            draggable={false}
            width={1000}
            height={1000}
            style={{
              width: "clamp(300px, 100%, 1000px)",
              height: "auto",
              position: "relative",
              zIndex: 2,
            }}
            onLoadingComplete={(e) => {
              e.style.backgroundImage = "none";
            }}
          />
        </div>
        <div className="info-items-wrapper">
          <div className="info-item 1">
            <Image
              src={MoneyEmoji}
              alt="Emoji"
              width={40}
              height={40}
              className="icon"
              draggable={false}
            />
            <div className="text">
              <span className="main-text">100% free</span>
              No ads. No paywalls.
            </div>
          </div>
          <div className="info-item 2">
            <Image
              src={HeartEmoji}
              alt="Emoji"
              width={40}
              height={40}
              className="icon"
              draggable={false}
            />
            <div className="text">
              <span className="main-text">Student-backed</span>
              Made by students, just like you.
            </div>
          </div>
          <div className="info-item 3">
            <Image
              src={LockedEmoji}
              alt="Emoji"
              width={40}
              height={40}
              className="icon"
              draggable={false}
            />
            <div className="text">
              <span className="main-text">Privacy First</span>
              <span>
                Your studying is your business.{" "}
                <Tooltip title="Privacy Statement">
                  <span role="button">
                    <MdInfoOutline
                      style={{
                        cursor: "pointer",
                        display: "inline",
                        transform: "translateY(3px)",
                        opacity: 0.7,
                      }}
                      onClick={() => {
                        router.push("/legal/privacy");
                      }}
                    />
                  </span>
                </Tooltip>
              </span>
            </div>
          </div>
          <div className="info-item 4">
            <Image
              src={RocketEmoji}
              alt="Emoji"
              width={40}
              height={40}
              className="icon"
              draggable={false}
            />
            <div className="text">
              <span className="main-text">Instant Studying</span>
              Import a set in seconds.
            </div>
          </div>
        </div>
      </IntroArea>

      <ProductImagesSection>
        <div className="section1">
          <div className="text">
            <Typography variant="h2">Learn mode, but better</Typography>
            <Typography
              variant="h5"
              component="h3"
              sx={{
                mt: "6rem",
              }}
            >
              <span className="title">Intelligent questions</span> use your
              previous answers to show you terms you&apos;re most likely to
              forget, to <UnderlineEmphasis>lock in</UnderlineEmphasis> the
              material.
            </Typography>
            <div className="info-item">
              <Image
                src={ClockEmoji}
                alt="Emoji"
                width={40}
                height={40}
                draggable={false}
              />
              <Typography
                variant="h6"
                component="h4"
                sx={{
                  fontWeight: 400,
                }}
              >
                Questions will reappear at the right time whether you know the
                material or still need to study.
              </Typography>
            </div>
            <div className="info-item">
              <Image
                src={CalendarEmoji}
                alt="Emoji"
                width={40}
                height={40}
                draggable={false}
              />
              <Typography
                variant="h6"
                component="h4"
                sx={{
                  fontWeight: 400,
                }}
              >
                The study algorithm focuses on both new terms and older terms to
                improve long-term retention.
              </Typography>
            </div>
            <Typography
              variant="h5"
              component="h3"
              sx={{
                mt: "16rem",
                "@media (max-width: 600px)": {
                  mt: "5rem",
                },
              }}
            >
              <span className="title">Instant feedback</span> helps you learn
              faster and be more{" "}
              <UnderlineEmphasis>confident</UnderlineEmphasis> before the test.
            </Typography>
            <div className="info-item">
              <Image
                src={LightEmoji}
                alt="Emoji"
                width={40}
                height={40}
                draggable={false}
              />
              <Typography
                variant="h6"
                component="h4"
                sx={{
                  fontWeight: 400,
                }}
              >
                All study tools take every opportunity to show you the right
                answer, so you can learn from your mistakes.
              </Typography>
            </div>
            <div className="info-item">
              <Image
                src={ScoreEmoji}
                alt="Emoji"
                width={40}
                height={40}
                draggable={false}
              />
              <Typography
                variant="h6"
                component="h4"
                sx={{
                  fontWeight: 400,
                }}
              >
                See your progress over time and how you&apos;re improving.
              </Typography>
            </div>

            <div
              ref={image2Ref}
              className="imageRef"
              style={{
                marginTop: "6rem",
              }}
            />
            <Typography
              variant="h5"
              component="h3"
              sx={{
                mt: "10rem",
                "@media (max-width: 600px)": {
                  mt: "5rem",
                },
              }}
            >
              <span className="title">Focused on improvement</span> to encourage
              and <UnderlineEmphasis>motivate</UnderlineEmphasis> you to learn
              more.
            </Typography>
            <div className="info-item">
              <Image
                src={PartyEmoji}
                alt="Emoji"
                width={40}
                height={40}
                draggable={false}
              />
              <Typography
                variant="h6"
                component="h4"
                sx={{
                  fontWeight: 400,
                }}
              >
                When you&apos;re excited, we&apos;re excited. We&apos;ll
                celebrate your accomplishments with confetti and encouragement.
              </Typography>
            </div>
            <div className="info-item">
              <Image
                src={PhoneEmoji}
                alt="Emoji"
                width={40}
                height={40}
                draggable={false}
              />
              <Typography
                variant="h6"
                component="h4"
                sx={{
                  fontWeight: 400,
                }}
              >
                No excuses for not studying. You can study anywhere on your
                phone.
              </Typography>
            </div>
            <div
              ref={image3Ref}
              className="imageRef"
              style={{
                marginTop: "8rem",
              }}
            />
          </div>
          <div className="images">
            <Image
              src={theme.darkMode ? QuestionPreviewDark : QuestionPreviewLight}
              alt="Learn Mode Image"
              width={500}
              draggable={false}
              className={`image first-image${
                imageVisible === 1 ? " visible" : ""
              }`}
            />
            <Image
              src={
                theme.darkMode
                  ? QuestionPreviewIncorrectDark
                  : QuestionPreviewIncorrectLight
              }
              alt="Learn Mode Image"
              width={500}
              draggable={false}
              className={`image second-image${
                imageVisible === 2 ? " visible" : ""
              }`}
            />
            <Image
              src={
                theme.darkMode
                  ? QuestionPreviewCorrectDark
                  : QuestionPreviewCorrectLight
              }
              alt="Learn Mode Image"
              width={500}
              draggable={false}
              className={`image third-image${
                imageVisible === 3 ? " visible" : ""
              }`}
            />
          </div>
        </div>
        <AboutSection>
          <div className="image">
            <Image
              src={CodePreviewImage}
              alt="Code Preview Image"
              width={500}
              draggable={false}
              className="inner-image"
            />
          </div>
          <div className="text">
            <Typography variant="h2">Development</Typography>
            <Typography
              variant="h5"
              component="h3"
              sx={{
                mt: "1rem",
              }}
            >
              Months of development went into making this app. As a student, I
              wanted to make a tool that would help me study more efficiently
              and effectively, and most importantly, <strong>free</strong>.
            </Typography>
            <Button
              variant="contained"
              color="inherit"
              sx={{
                mt: "2rem",
                backgroundColor: theme.colors.themed.alwaysLight,
                color: theme.colors.themed.alwaysDark,
                border: `1px solid ${theme.colors.themed.alwaysDark}`,
                "&:hover": {
                  backgroundColor: "transparent",
                  color: theme.colors.themed.alwaysLight,
                  border: `1px solid ${theme.colors.themed.alwaysLight}`,
                },
              }}
              target="_blank"
              href="https://kalinpatel.me/twoam"
            >
              Learn about the technical details
            </Button>
          </div>
        </AboutSection>
      </ProductImagesSection>
    </Layout>
  );
};

export default Home;
