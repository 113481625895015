import dynamic from "next/dynamic";
import { Suspense } from "react";
const UE = dynamic(
  () => import("underline-emphasis").then((mod) => mod.UnderlineEmphasis),
  {
    ssr: false,
  }
);

export default function UnderlineEmphasis({
  children,
  className,
}: {
  children?: string;
  className?: string;
}) {
  return (
    <Suspense fallback={<span className={className}>{children}</span>}>
      <UE>{children}</UE>
    </Suspense>
  );
}
